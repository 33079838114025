import { gql } from "@apollo/client";
import {
  BaseCharacterFields,
  BaseCharacterProductFields,
  BaseContentFields,
  BaseConversationFields,
  BaseMessageFields,
  BaseProductFields,
  BaseSourceFields,
  BaseUserFields,
} from "./fragments";

// Cache stuff: https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies

// === types === //

export enum UserType {
  Verified = "verified",
  Pending = "pending",
}

// ==== users ==== //
const CreateUser = gql`
  ${BaseUserFields}
  mutation CreateUser(
    $name: String!
    $email: String!
    $phoneNumber: String
    $referralCode: String
    $password: String
    $timezone: String
    $isElder: Boolean
  ) {
    createUser(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      referralCode: $referralCode
      password: $password
      timezone: $timezone
      isElder: $isElder
    ) {
      user {
        ...BaseUserFields
      }
      token
    }
  }
`;

const DeleteUser = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email)
  }
`;

const DeleteMe = gql`
  mutation DeleteMe {
    deleteMe
  }
`;

const UpdateUser = gql`
  ${BaseUserFields}
  mutation UpdateUser($name: String, $hasTwoFactorAuth: Boolean) {
    updateUser(name: $name, hasTwoFactorAuth: $hasTwoFactorAuth) {
      ...BaseUserFields
    }
  }
`;

const UnenrollTwoFactor = gql`
  mutation UnenrollTwoFactor {
    unenrollTwoFactor
  }
`;

const GetMe = gql`
  ${BaseUserFields}
  query GetMe {
    me {
      ...BaseUserFields
    }
  }
`;

const GetPaymentMethods = gql`
  query GetPaymentMethods {
    getPaymentMethods {
      last4
      source
    }
  }
`;

const GetConversation = gql`
  ${BaseConversationFields}
  query GetConversation($conversationId: ID!) {
    getConversation(conversationId: $conversationId) {
      ...BaseConversationFields
    }
  }
`;

const GetConversationCharacter = gql`
  ${BaseCharacterFields}
  query GetConversationCharacter($conversationId: ID!) {
    getConversationCharacter(conversationId: $conversationId) {
      ...BaseCharacterFields
    }
  }
`;

const GetMessages = gql`
  ${BaseMessageFields}
  query GetMessages($conversationId: ID!) {
    getMessages(conversationId: $conversationId) {
      ...BaseMessageFields
    }
  }
`;

const StartConversation = gql`
  ${BaseConversationFields}
  mutation StartConversation($characterId: ID!, $message: String) {
    startConversation(characterId: $characterId, message: $message) {
      ...BaseConversationFields
    }
  }
`;

const SendChat = gql`
  ${BaseMessageFields}
  mutation SendChat($conversationId: ID!, $message: String!) {
    sendChat(conversationId: $conversationId, message: $message) {
      message {
        ...BaseMessageFields
      }
    }
  }
`;

const GetCharacter = gql`
  ${BaseCharacterFields}
  query GetCharacter($characterId: ID!) {
    getCharacter(characterId: $characterId) {
      ...BaseCharacterFields
    }
  }
`;

const GetCharacters = gql`
  ${BaseCharacterFields}
  query GetCharacters {
    getCharacters {
      ...BaseCharacterFields
    }
  }
`;

const GetCharacterFavoriteProducts = gql`
  ${BaseCharacterProductFields}
  query GetCharacterFavoriteProducts($characterId: ID!) {
    getCharacterFavoriteProducts(characterId: $characterId) {
      ...BaseCharacterProductFields
    }
  }
`;

const GetCharacterContent = gql`
  ${BaseContentFields}
  query GetCharacterContent($characterId: ID!) {
    getCharacterContent(characterId: $characterId) {
      ...BaseContentFields
    }
  }
`;

const GetCharacterSources = gql`
  ${BaseSourceFields}
  ${BaseContentFields}
  query GetCharacterSources($characterId: ID!) {
    getCharacterSources(characterId: $characterId) {
      ...BaseSourceFields
      content {
        ...BaseContentFields
      }
    }
  }
`;

const UpdateCharacter = gql`
  ${BaseCharacterFields}
  mutation UpdateCharacter(
    $characterId: ID!
    $name: String
    $description: String
    $personalityPrompt: String
    $thumbnailImageUrl: String
    $defaultQuestions: [DefaultQuestionInput!]
  ) {
    updateCharacter(
      characterId: $characterId
      name: $name
      description: $description
      personalityPrompt: $personalityPrompt
      thumbnailImageUrl: $thumbnailImageUrl
      defaultQuestions: $defaultQuestions
    ) {
      ...BaseCharacterFields
    }
  }
`;

const ScrapeUrl = gql`
  query ScrapeUrl($url: String!) {
    scrapeUrl(url: $url) {
      images
      title
      body
    }
  }
`;

const ScrapeProductsFromMarkup = gql`
  query ScrapeProductsFromMarkup($url: String!, $markdown: String!) {
    scrapeProductsFromMarkup(url: $url, markdown: $markdown) {
      title
      imageUrl
      description
      vendorName
      shoppingUrl
      price
      productId
    }
  }
`;

const CreateContent = gql`
  ${BaseContentFields}
  mutation CreateContent(
    $characterId: ID!
    $sourceId: ID!
    $type: ContentTypeEnum!
    $sourceUrl: String!
    $description: String!
    $content: String!
    $title: String!
    $thumbnailImageUrl: String!
    $items: [ContentItemInput!]!
    $products: [ContentProductInput!]!
  ) {
    createContent(
      characterId: $characterId
      sourceId: $sourceId
      type: $type
      sourceUrl: $sourceUrl
      description: $description
      content: $content
      title: $title
      thumbnailImageUrl: $thumbnailImageUrl
      items: $items
      products: $products
    ) {
      ...BaseContentFields
    }
  }
`;

const UpdateContent = gql`
  ${BaseContentFields}
  mutation UpdateContent(
    $contentId: ID!
    $sourceUrl: String
    $type: ContentTypeEnum
    $description: String
    $content: String
    $title: String
    $thumbnailImageUrl: String
    $items: [ContentItemInput!]!
    $products: [ContentProductInput!]!
  ) {
    updateContent(
      contentId: $contentId
      sourceUrl: $sourceUrl
      type: $type
      title: $title
      description: $description
      content: $content
      thumbnailImageUrl: $thumbnailImageUrl
      items: $items
      products: $products
    ) {
      ...BaseContentFields
    }
  }
`;

const CreateSource = gql`
  ${BaseSourceFields}
  mutation CreateSource(
    $characterId: ID!
    $url: String!
    $description: String!
    $content: String!
  ) {
    createSource(
      characterId: $characterId
      url: $url
      description: $description
      content: $content
    ) {
      ...BaseSourceFields
    }
  }
`;

const EmbedContent = gql`
  ${BaseContentFields}
  mutation EmbedContent($contentId: ID!) {
    embedContent(contentId: $contentId) {
      ...BaseContentFields
    }
  }
`;

const EmbedCharacterContent = gql`
  mutation EmbedContent($characterId: ID!) {
    embedCharacterContent(characterId: $characterId)
  }
`;

const DescribeImage = gql`
  query describeImage($url: String!) {
    describeImage(url: $url) {
      description
      keywords
    }
  }
`;

const GetContent = gql`
  ${BaseContentFields}
  query GetContent($contentId: ID!) {
    getContent(contentId: $contentId) {
      ...BaseContentFields
    }
  }
`;

const CreateProduct = gql`
  ${BaseProductFields}
  mutation CreateProduct(
    $title: String!
    $imageUrl: String!
    $description: String!
    $vendorName: String!
    $shoppingUrl: String!
    $priceCents: Float!
  ) {
    createProduct(
      title: $title
      imageUrl: $imageUrl
      description: $description
      vendorName: $vendorName
      shoppingUrl: $shoppingUrl
      priceCents: $priceCents
    ) {
      ...BaseProductFields
    }
  }
`;

const UpdateProduct = gql`
  ${BaseProductFields}
  mutation UpdateProduct(
    $productId: ID!
    $title: String
    $imageUrl: String
    $description: String
    $vendorName: String
    $shoppingUrl: String
    $priceCents: Float
  ) {
    updateProduct(
      productId: $productId
      title: $title
      imageUrl: $imageUrl
      description: $description
      vendorName: $vendorName
      shoppingUrl: $shoppingUrl
      priceCents: $priceCents
    ) {
      ...BaseProductFields
    }
  }
`;

const GetProducts = gql`
  ${BaseProductFields}
  query GetProducts {
    getProducts {
      ...BaseProductFields
    }
  }
`;

const SearchProduct = gql`
  ${BaseProductFields}
  query SearchProduct($query: String!) {
    searchProduct(query: $query) {
      ...BaseProductFields
    }
  }
`;

const DeleteContent = gql`
  mutation DeleteContent($contentId: ID!) {
    deleteContent(contentId: $contentId)
  }
`;

export const api = {
  products: {
    create: CreateProduct,
    update: UpdateProduct,
    list: GetProducts,
    search: SearchProduct,
  },
  users: {
    create: CreateUser,
    delete: DeleteUser,
    deleteMe: DeleteMe,
    update: UpdateUser,
    unenrollTwoFactor: UnenrollTwoFactor,
    me: GetMe,
    paymentMethods: GetPaymentMethods,
  },
  characters: {
    update: UpdateCharacter,
    list: GetCharacters,
    retrieve: GetCharacter,
    favProducts: GetCharacterFavoriteProducts,
    content: GetCharacterContent,
    sources: GetCharacterSources,
  },
  conversations: {
    start: StartConversation,
    retrieve: GetConversation,
    messages: GetMessages,
    send: SendChat,
    character: GetConversationCharacter,
  },
  content: {
    createSource: CreateSource,
    retrieve: GetContent,
    describeImage: DescribeImage,
    embed: EmbedContent,
    embedAllCharacterContent: EmbedCharacterContent,
    create: CreateContent,
    update: UpdateContent,
    scrape: ScrapeUrl,
    scrapeProducts: ScrapeProductsFromMarkup,
    delete: DeleteContent,
  },
};
