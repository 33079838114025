import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "src/api";
import { Mutation, MutationSendChatArgs } from "src/api/generated/types";
import { Button } from "src/components/Button";
import { Textarea } from "src/components/Form";
import { getActiveCharacter } from "src/redux/reducers/activeCharacter";
import { getConversation } from "src/redux/reducers/chat";
import { colors } from "src/theme";

export const MessageInput = () => {
  const character = useSelector(getActiveCharacter);
  const conversation = useSelector(getConversation);

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [startConversation] = useMutation<Pick<Mutation, "startConversation">>(
    api.conversations.start
  );

  const [sendChat] = useMutation<Pick<Mutation, "sendChat">>(
    api.conversations.send
  );

  const _sendChat = async () => {
    if (!character) {
      return;
    }

    if (!conversation) {
      const convoResponse = await startConversation({
        variables: {
          characterId: character?.id || "",
        },
        refetchQueries: [api.conversations.messages],
      });

      navigate(
        `/dashboard/${character.id}/chats/${convoResponse.data?.startConversation?.id}`
      );

      const variables: MutationSendChatArgs = {
        conversationId: convoResponse.data?.startConversation?.id || "",
        message: message,
      };

      const response = await sendChat({
        variables,
        refetchQueries: [api.conversations.messages],
      });

      const data = response.data?.sendChat;

      if (!data) {
        return;
      }

      setMessage("");

      return;
    }

    try {
      const variables: MutationSendChatArgs = {
        conversationId: conversation?.id || "",
        message: message,
      };

      const response = await sendChat({
        variables,
        refetchQueries: [api.conversations.messages],
      });

      const data = response.data?.sendChat;

      if (!data) {
        return;
      }

      setMessage("");
    } catch (err) {
      console.log(err);
      //
    }
  };

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <Textarea
        placeholder={`Message ${character?.name || ""}`}
        style={{
          padding: "15px",
          width: "100%",
          paddingRight: "40px",
          borderRadius: "10px",
          fontSize: "16px",
        }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button
        disabled={!message}
        onClick={_sendChat}
        style={{
          opacity: message ? 1 : 0.5,
          position: "absolute",
          right: 15,
          bottom: 30,
          zIndex: 100,
          borderRadius: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: 35,
          width: 35,
          background: "none",
          backgroundColor: colors.primary,
          border: "none",
          cursor: "pointer",
        }}
      >
        <i
          className="fas fa-arrow-up"
          style={{ color: "white", fontSize: 18 }}
        />
      </Button>
    </div>
  );
};
