export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  Date: { input: Date; output: Date; }
};

export type Character = {
  __typename?: 'Character';
  cardImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  defaultQuestions?: Maybe<Array<CharacterGeneralQuestion>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images?: Maybe<Array<CharacterImage>>;
  name?: Maybe<Scalars['String']['output']>;
  personalityPrompt?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export enum CharacterDataSource {
  Background = 'Background',
  Blog = 'Blog',
  Faq = 'FAQ',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Pinterest = 'Pinterest',
  Podcast = 'Podcast',
  Shop = 'Shop',
  Sitemap = 'Sitemap',
  Substack = 'Substack',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  Website = 'Website',
  YouTube = 'YouTube'
}

export type CharacterGeneralQuestion = {
  __typename?: 'CharacterGeneralQuestion';
  question?: Maybe<Scalars['String']['output']>;
};

export type CharacterImage = {
  __typename?: 'CharacterImage';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  resizeMode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type CharacterProduct = {
  __typename?: 'CharacterProduct';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isFavorite: Scalars['Boolean']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceFormatted?: Maybe<Scalars['String']['output']>;
  resizeMode?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  vendorName?: Maybe<Scalars['String']['output']>;
};

export type ChatResponse = {
  __typename?: 'ChatResponse';
  message: Message;
};

export type Content = {
  __typename?: 'Content';
  character: Character;
  characterId: Scalars['String']['output'];
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  items?: Maybe<Array<ContentItem>>;
  lastEmbeddedAt?: Maybe<Scalars['Date']['output']>;
  products?: Maybe<Array<ContentProduct>>;
  source?: Maybe<Source>;
  sourceId: Scalars['ID']['output'];
  sourceUrl?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: ContentTypeEnum;
  updatedAt: Scalars['Date']['output'];
};

export type ContentItem = {
  __typename?: 'ContentItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  position: Scalars['Int']['output'];
  productIds?: Maybe<Array<Scalars['ID']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  type: ContentItemTypeEnum;
};

export type ContentItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  position: Scalars['Int']['input'];
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: ContentItemTypeEnum;
};

export enum ContentItemTypeEnum {
  Image = 'Image',
  Outfit = 'Outfit'
}

export type ContentProduct = {
  __typename?: 'ContentProduct';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  priceCents?: Maybe<Scalars['Float']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  shoppingUrl: Scalars['String']['output'];
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  vendorName: Scalars['String']['output'];
};

export type ContentProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  position: Scalars['Int']['input'];
  priceCents?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  shoppingUrl: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  vendorName: Scalars['String']['input'];
};

export enum ContentTypeEnum {
  Blog = 'Blog',
  Collection = 'Collection',
  Faq = 'FAQ',
  Gallery = 'Gallery',
  Image = 'Image',
  Outfit = 'Outfit',
  Podcast = 'Podcast',
  Product = 'Product'
}

export type Conversation = {
  __typename?: 'Conversation';
  character?: Maybe<Character>;
  characterId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  startingPrompt?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['ID']['output'];
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  token: Scalars['String']['output'];
  user: User;
};

export type DefaultQuestionInput = {
  question?: InputMaybe<Scalars['String']['input']>;
};

export type DescribeImageResponse = {
  __typename?: 'DescribeImageResponse';
  description?: Maybe<Scalars['String']['output']>;
  keywords: Array<Scalars['String']['output']>;
};

export type Message = {
  __typename?: 'Message';
  content?: Maybe<Array<MessageContent>>;
  createdAt: Scalars['Date']['output'];
  fromType: MessageFromTypeEnum;
  id: Scalars['ID']['output'];
  idempotencyKey?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  sections: Array<MessageSection>;
  updatedAt: Scalars['Date']['output'];
};

export type MessageContent = {
  __typename?: 'MessageContent';
  characterId: Scalars['ID']['output'];
  content?: Maybe<Content>;
  contentId?: Maybe<Scalars['ID']['output']>;
  conversationId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  highlight?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageDescription?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  messageId: Scalars['ID']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export enum MessageFromTypeEnum {
  Character = 'Character',
  User = 'User'
}

export type MessageSection = {
  __typename?: 'MessageSection';
  content?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  relatedMessageContentId?: Maybe<Scalars['ID']['output']>;
  subsections?: Maybe<Array<MessageSection>>;
  title?: Maybe<Scalars['String']['output']>;
  type: MessageSectionTypeEnum;
};

export enum MessageSectionTypeEnum {
  Image = 'Image',
  ImageGroup = 'ImageGroup',
  Outfit = 'Outfit',
  Product = 'Product',
  Text = 'Text'
}

export type Mutation = {
  __typename?: 'Mutation';
  createCharacter: Character;
  createContent: Content;
  createConversation: Conversation;
  createProduct: Product;
  createSource: Source;
  createUser: CreateUserResponse;
  deleteContent: Scalars['String']['output'];
  embedCharacterContent: Scalars['String']['output'];
  embedContent: Content;
  sendChat: ChatResponse;
  startConversation: Conversation;
  updateCharacter: Character;
  updateContent: Content;
  updateContentProduct: ContentProduct;
  updateMe: User;
  updateProduct: Product;
};


export type MutationCreateCharacterArgs = {
  description: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateContentArgs = {
  characterId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  description: Scalars['String']['input'];
  items: Array<ContentItemInput>;
  products: Array<ContentProductInput>;
  sourceId: Scalars['ID']['input'];
  sourceUrl: Scalars['String']['input'];
  thumbnailImageUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: ContentTypeEnum;
};


export type MutationCreateConversationArgs = {
  characterId: Scalars['ID']['input'];
};


export type MutationCreateProductArgs = {
  description: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  priceCents: Scalars['Float']['input'];
  shoppingUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
};


export type MutationCreateSourceArgs = {
  characterId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String']['input'];
  isElder?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteContentArgs = {
  contentId: Scalars['ID']['input'];
};


export type MutationEmbedCharacterContentArgs = {
  characterId: Scalars['ID']['input'];
};


export type MutationEmbedContentArgs = {
  contentId: Scalars['ID']['input'];
};


export type MutationSendChatArgs = {
  conversationId: Scalars['ID']['input'];
  idempotency?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  testPrompt?: InputMaybe<Scalars['String']['input']>;
};


export type MutationStartConversationArgs = {
  characterId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCharacterArgs = {
  characterId: Scalars['ID']['input'];
  defaultQuestions?: InputMaybe<Array<DefaultQuestionInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  personalityPrompt?: InputMaybe<Scalars['String']['input']>;
  thumbnailImageUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateContentArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  contentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  items: Array<ContentItemInput>;
  products: Array<ContentProductInput>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  thumbnailImageUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContentTypeEnum>;
};


export type MutationUpdateContentProductArgs = {
  contentProductId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  shoppingUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
};


export type MutationUpdateMeArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateProductArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  priceCents?: InputMaybe<Scalars['Float']['input']>;
  productId: Scalars['ID']['input'];
  shoppingUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
};

export type Product = {
  __typename?: 'Product';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  resizeMode?: Maybe<Scalars['String']['output']>;
  shoppingUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  vendorName?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  describeImage: DescribeImageResponse;
  getCharacter?: Maybe<Character>;
  getCharacterContent: Array<Content>;
  getCharacterFavoriteProducts: Array<CharacterProduct>;
  getCharacterSources: Array<Source>;
  getCharacters: Array<Character>;
  getContent: Content;
  getContentForSource: Array<Content>;
  getConversation: Conversation;
  getConversationCharacter: Character;
  getConversations: Array<Conversation>;
  getMessages: Array<Message>;
  getProducts: Array<Product>;
  getRecommendedQuestions: Array<RecommendedQuestion>;
  getRelevantContent: Array<Content>;
  me?: Maybe<User>;
  processContent: Content;
  scrapeProductsFromMarkup: Array<ScrapedProductData>;
  scrapeUrl: ScrapeResponse;
  searchProducts: Array<Product>;
};


export type QueryDescribeImageArgs = {
  url: Scalars['String']['input'];
};


export type QueryGetCharacterArgs = {
  characterId: Scalars['ID']['input'];
};


export type QueryGetCharacterContentArgs = {
  characterId: Scalars['ID']['input'];
};


export type QueryGetCharacterFavoriteProductsArgs = {
  characterId: Scalars['ID']['input'];
};


export type QueryGetCharacterSourcesArgs = {
  characterId: Scalars['ID']['input'];
};


export type QueryGetContentArgs = {
  contentId: Scalars['ID']['input'];
};


export type QueryGetContentForSourceArgs = {
  sourceId: Scalars['ID']['input'];
};


export type QueryGetConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type QueryGetConversationCharacterArgs = {
  conversationId: Scalars['ID']['input'];
};


export type QueryGetConversationsArgs = {
  characterId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetMessagesArgs = {
  conversationId: Scalars['ID']['input'];
};


export type QueryGetProductsArgs = {
  characterId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetRelevantContentArgs = {
  characterId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
};


export type QueryProcessContentArgs = {
  contentId: Scalars['ID']['input'];
};


export type QueryScrapeProductsFromMarkupArgs = {
  markdown: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type QueryScrapeUrlArgs = {
  url: Scalars['String']['input'];
};


export type QuerySearchProductsArgs = {
  query: Scalars['String']['input'];
};

export type RecommendedQuestion = {
  __typename?: 'RecommendedQuestion';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ScrapeResponse = {
  __typename?: 'ScrapeResponse';
  body: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ScrapedProductData = {
  __typename?: 'ScrapedProductData';
  description?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  shoppingUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  vendorName?: Maybe<Scalars['String']['output']>;
};

export type Source = {
  __typename?: 'Source';
  content?: Maybe<Array<Content>>;
  createdAt: Scalars['Date']['output'];
  dataSource: CharacterDataSource;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  sourceImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  activeGroupId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isSuperuser: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export enum UserTypeEnum {
  Pending = 'Pending',
  Verified = 'Verified'
}
