import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  FormLabel,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { BaseProductFields } from "src/api/fragments";
import {
  MutationCreateProductArgs,
  MutationUpdateProductArgs,
} from "src/api/generated/types";
import { Input, Textarea } from "src/components/Form";
import { Modal } from "src/components/Modal";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { storage } from "src/utils/firebase";
import { D } from "src/utils/helpers";
import { v4 as uuidv4 } from "uuid";

type Props = InjectedProps & {
  product?: BaseProductFields;
  onSuccess: (image: BaseProductFields) => void;
};

function _ProductModal({
  handleHide,
  product,
  show: isVisible,
  onSuccess,
}: Props) {
  const toast = useMyToast();

  const [createProduct] = useMutation(api.products.create);
  const [updateProduct] = useMutation(api.products.update);

  const [imageUrl, setImageUrl] = useState(product?.imageUrl || "");
  const [description, setDescription] = useState(product?.description || "");
  const [title, setTitle] = useState(product?.title || "");
  const [shoppingUrl, setShoppingUrl] = useState(product?.shoppingUrl || "");
  const [vendorName, setVendorName] = useState(product?.vendorName || "");
  const [price, setPrice] = useState<string>(
    D(product?.priceCents || 0)
      .toUnit()
      .toString()
  );

  const setFileUpload = ({ name, url }: { name: string; url: string }) => {
    setImageUrl(url);
  };

  const _onSubmit = async function () {
    try {
      if (product) {
        // update it
        const variables: MutationUpdateProductArgs = {
          imageUrl,
          description,
          title,
          priceCents: D(parseFloat(price) * 100).getAmount(),
          shoppingUrl,
          vendorName,
          productId: product.id,
        };

        const response = await updateProduct({
          variables,
          refetchQueries: [api.products.list],
        });

        const newProduct = response.data?.updateProduct;

        if (!newProduct) {
          throw new Error("An error occurred.");
        }

        onSuccess(newProduct);

        handleHide();

        return;
      }

      // otherwise create the product
      const params: MutationCreateProductArgs = {
        imageUrl,
        description,
        title,
        priceCents: D(parseFloat(price) * 100).getAmount(),
        shoppingUrl,
        vendorName,
      };

      const response = await createProduct({
        variables: params,
        refetchQueries: [api.products.list],
      });

      const newProduct = response.data?.createProduct;

      if (!newProduct) {
        throw new Error("An error occurred.");
      }

      onSuccess(newProduct);

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={<>Product Modal</>}
      isVisible={isVisible}
      handleHide={handleHide}
      preserveScrollBarGap
      Footer={
        <Button
          width="100%"
          variant="primary"
          marginBottom="1rem"
          onClick={_onSubmit}
        >
          Save
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <ImageUpload
          fileUpload={{
            name: title,
            url: imageUrl,
          }}
          onFileUpload={setFileUpload}
        />

        <br />

        <Input
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <Input
          label="Vendor"
          value={vendorName}
          onChange={(e) => setVendorName(e.target.value)}
        />

        <Input
          label="Product URL"
          value={shoppingUrl}
          onChange={(e) => setShoppingUrl(e.target.value)}
        />

        <Input
          label="Price"
          value={price}
          type="number"
          onChange={(e) => setPrice(e.target.value)}
        />

        <Textarea
          label="Description"
          subtitle="This is what we will use to determine if this image is relevant to a user's question."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <br />
      </Container>
    </Modal>
  );
}

const ImageUpload = ({
  fileUpload,
  onFileUpload,
}: {
  fileUpload: { name: string; url: string } | null;
  onFileUpload: (p: { name: string; url: string }) => void;
}) => {
  const toast = useMyToast();
  const { characterId } = useParams<{ characterId: string }>();
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (!file || !characterId) return;

    try {
      setLoading(true);

      // upload the files to firebase
      const fileRef = ref(
        storage,
        `characters/${characterId}/content/${uuidv4()}-${file.name}`
      );

      await uploadBytes(fileRef, file);

      const url = await getDownloadURL(fileRef);

      onFileUpload({
        name: file.name,
        url,
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      // image files. jpg or png or PDF
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    onDropRejected: (errors: any[]) => {
      // console.log(err);
      const message = errors
        .map((e) => e.errors.map((e: any) => e.message))
        .join(" ");
      toast.show({
        message,
        status: "error",
      });
    },
  });

  const hasFile = !!fileUpload?.url;
  const theme = useTheme();

  return (
    <>
      <FormLabel color={theme.text} fontSize={14} marginBottom="6px">
        Upload an image
      </FormLabel>

      {hasFile && (
        <HStack
          style={{
            marginBottom: "1rem",
            padding: "1rem 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            border: `1px solid ` + theme.border,
          }}
          bg={theme.secondaryBackground}
        >
          <img
            src={fileUpload?.url}
            style={{
              width: 40,
              height: 40,
              borderRadius: 10,
              objectFit: "cover",
            }}
          />

          <Text flex={1} color={theme.header} fontSize="sm">
            {loading ? (
              <>
                Uploading... <Spinner size="xs" />{" "}
              </>
            ) : (
              fileUpload?.name || "File"
            )}
          </Text>

          <a href={fileUpload?.url} target="_blank" rel="noreferrer">
            <i
              style={{
                fontSize: 20,
                color: colors.primary,
                marginLeft: 5,
              }}
              className="fas fa-external-link"
            />
          </a>
        </HStack>
      )}

      {!hasFile && (
        <div
          style={{
            padding: "2rem 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            border: `1px dashed ` + colors.gray80,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text color={theme.header} fontSize="md" fontWeight="semibold">
              Drop the files here ...
            </Text>
          ) : (
            <Box textAlign="center">
              <i
                style={{ color: colors.primary, fontSize: 40 }}
                className="fas fa-camera-viewfinder"
              />
              <Text
                color={theme.header}
                marginTop="1rem"
                fontWeight="bold"
                fontSize="md"
              >
                Drop{" "}
                {/* or <span style={{ color: colors.primary }}>browse</span>{" "} */}
                your file.
              </Text>
            </Box>
          )}
        </div>
      )}
    </>
  );
};

export const ProductModal = connectModal({
  name: "ProductModal",
})(_ProductModal);
