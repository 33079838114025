import { useNavigate } from "react-router-dom";
import { useTheme } from "src/hooks";

export const LargeProductCard = ({ imageUrl }: { imageUrl: string }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const _onPress = () => {
    // navigate('/product-details');
  };

  return (
    <div
      onClick={_onPress}
      style={{
        marginTop: 15,
        position: "relative",
        cursor: "pointer",
        width: "100%",
      }}
    >
      <img
        src={
          "https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F2a6e6cfb-44a4-4621-907d-3d7f8f75ce37_3024x4032.heic" ||
          imageUrl
        }
        alt="Product"
        style={{
          height: 300,
          width: 300,
          objectFit: "cover",
          borderRadius: 20,
          boxShadow: `0 2px 4px rgba(0, 0, 0, 0.08)`,
          borderColor: theme.border,
          borderWidth: 1,
          borderStyle: "solid",
        }}
      />

      <div
        style={{
          position: "absolute",
          bottom: 15,
          left: 15,
          borderRadius: 100,
          overflow: "hidden",
          height: 45,
          //   border: "1px solid #f0e5db",
        }}
      >
        <div
          style={{
            height: "100%",
            padding: "0 10px",
            display: "flex",
            alignItems: "center",
            backdropFilter: "blur(10px)", // CSS for blur effect
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/thequickfix-app.appspot.com/o/characters%2Fleandra%2Fimage%20(15).png?alt=media&token=641983ed-f266-475d-b318-94d6b99c6551"
            alt="Leandra Cohen"
            style={{
              height: 30,
              width: 30,
              marginRight: 5,
              borderRadius: "50%",
            }}
          />

          <span
            style={{
              fontSize: 16,
              fontWeight: "medium",
            }}
          >
            Leandra Cohen
          </span>
        </div>
      </div>
    </div>
  );
};
