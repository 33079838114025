import { BaseContentFields } from "src/api/fragments";
import {
  ContentItemInput,
  ContentItemTypeEnum,
  ContentProductInput,
  ContentTypeEnum,
} from "src/api/generated/types";
import { D } from "src/utils/helpers";
import * as yup from "yup";

const itemSchema = yup.object().shape({
  id: yup.string().required("ID is required.").nullable(),
  type: yup.string().required("Type is required.").nullable(),
  imageUrl: yup.string().optional().nullable(),
  title: yup.string().optional().nullable(),
  description: yup.string().optional().nullable(),
  productIds: yup.array().of(yup.string()).nullable(),
});

const productSchema = yup.object().shape({
  id: yup.string().required("ID is required.").nullable(),
  productId: yup.string().nullable(),
  title: yup.string().nullable(),
  vendorName: yup.string().optional().nullable(),
  imageUrl: yup.string().optional().nullable(),
  shoppingUrl: yup.string().optional().nullable(),
  description: yup.string().optional().nullable(),
  price: yup.number().optional().nullable(),
});

export const schema = yup.object().shape({
  type: yup.string().oneOf(Object.values(ContentTypeEnum)).nullable(),
  url: yup.string().nullable(),
  title: yup.string().nullable(),
  content: yup.string().nullable(),
  items: yup.array().of(itemSchema).nullable(),
  products: yup.array().of(productSchema).nullable(),
});

export type ItemFormValue = {
  id: string;
  type: ContentItemTypeEnum;
  imageUrl?: string | null;
  title: string;
  description: string;
  productIds: string[];
  keywords: string[] | null;
};

export type ProductFormValue = {
  id: string;
  productId: string | null;
  title: string;
  vendorName: string;
  imageUrl: string;
  shoppingUrl: string;
  description: string;
  keywords: string[] | null;
  price: number;
};

export type FormValues = {
  type: ContentTypeEnum;
  url: string;
  title: string;
  content: string;
  items: ItemFormValue[];
  products: ProductFormValue[];
};

export const DEFAULT_VALUES: FormValues = {
  type: ContentTypeEnum.Blog,
  url: "",
  title: "",
  content: "",
  items: [],
  products: [],
};

export const TO_ITEM_FORM_VALUES = (
  img: BaseContentFields["items"][0],
  index: number
): ItemFormValue => ({
  id: img.id,
  type: img.type,
  imageUrl: img.imageUrl || "",
  title: img.title || "",
  description: img.description || "",
  productIds: img.productIds ?? [],
  keywords: img.keywords ?? [],
});

export const TO_PRODUCT_FORM_VALUES = (
  product: BaseContentFields["products"][0],
  index: number
): ProductFormValue => ({
  id: product.id,
  productId: product.productId || null,
  title: product.title || "",
  vendorName: product.vendorName,
  imageUrl: product.imageUrl || "",
  shoppingUrl: product.shoppingUrl,
  description: product.description || "",
  price: D(product.priceCents ?? 0).toUnit(),
  keywords: product.keywords ?? [],
});

export const TO_ITEM_GQL = (
  img: ItemFormValue,
  i: number
): ContentItemInput => ({
  id: img.id,
  imageUrl: img.imageUrl,
  type: img.type,
  position: i,
  title: img.title,
  description: img.description,
  productIds: img.productIds,
  keywords: img.keywords || [],
});

export const TO_PRODUCT_GQL = (
  product: ProductFormValue,
  i: number
): ContentProductInput => ({
  id: product.id,
  productId: product.productId || null,
  title: product.title || "",
  position: i,
  priceCents: D(product.price * 100).getAmount(),
  vendorName: product.vendorName,
  imageUrl: product.imageUrl || "",
  shoppingUrl: product.shoppingUrl,
  description: product.description || "",
  keywords: product.keywords || [],
});
