import { useQuery } from "@apollo/client";
import { Spinner, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { BaseContentFields, BaseSourceFields } from "src/api/fragments";
import { Query } from "src/api/generated/types";
import { Touchable } from "src/components/Touchable";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks";
import { colors } from "src/theme";
import { Modal } from "../../Modal";
import ContentForm from "./ContentForm";
import {
  DEFAULT_VALUES,
  FormValues,
  schema,
  TO_ITEM_FORM_VALUES,
  TO_PRODUCT_FORM_VALUES,
} from "./form";

export type ContentModalProps = {
  contentId: string;
  sourceId: string;
  source?: BaseSourceFields;
};

function _ContentModal({
  handleHide,
  show: isVisible,
  contentId,
  sourceId,
  source,
}: InjectedProps & ContentModalProps) {
  const { data, loading } = useQuery<Pick<Query, "getContent">>(
    api.content.retrieve,
    {
      variables: {
        contentId: contentId,
      },
      fetchPolicy: "network-only",
    }
  );

  const content = (data?.getContent ?? null) as Maybe<BaseContentFields>;
  const [_, setSearchParams] = useSearchParams();

  const formProps = useForm<FormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: DEFAULT_VALUES,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    getValues,
    setValue,
    reset,
  } = formProps;

  useEffect(() => {
    reset({
      type: content?.type || DEFAULT_VALUES.type,
      url: content?.sourceUrl || DEFAULT_VALUES.url,
      title: content?.title || DEFAULT_VALUES.title,
      content: content?.content || DEFAULT_VALUES.content,
      items:
        (content?.items ?? []).map(TO_ITEM_FORM_VALUES).sort() ||
        DEFAULT_VALUES.items,
      products:
        (content?.products ?? []).map(TO_PRODUCT_FORM_VALUES).sort() ||
        DEFAULT_VALUES.products,
    });
  }, [content]);

  const hide = () => {
    handleHide();
    // remove contentId from search params
    setSearchParams({});
  };

  return (
    <FormProvider {...formProps}>
      <Modal
        autoFocus={false}
        isVisible={isVisible}
        handleHide={hide}
        minW="70vw"
        // make it so cannot scroll the background
        blockScrollOnMount={true}
      >
        <div
          style={{
            display: "flex",
            height: "90vh",
            padding: "15px 0",
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Spinner
                color={colors.primary}
                // make larger in size
                thickness="4px"
              />
            </div>
          ) : (
            <ContentForm
              onComplete={hide}
              content={content}
              sourceId={sourceId}
              source={source}
            />
          )}
        </div>
      </Modal>
    </FormProvider>
  );
}

const Sidebar = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        width: 250,
        padding: 10,
        height: "100%",
        flexShrink: 0,
        marginRight: 20,
        borderRadius: 15,
        background: theme.secondaryBackground,
      }}
    >
      <DataSourceTab
        imageUrl="https://firebasestorage.googleapis.com/v0/b/modiste-dev.appspot.com/o/icons%2Fsubstack.png?alt=media&token=60b15696-19bf-43ef-8438-1d34c051ec1a"
        name="Substack"
      />

      <DataSourceTab iconName="fab fa-instagram" name="Instagram" />

      <DataSourceTab iconName="fas fa-browser" name="Website" />

      <DataSourceTab iconName="fas fa-code" name="Sitemap" />
    </div>
  );
};

const DataSourceTab = ({
  imageUrl,
  name,
  iconName,
}: {
  imageUrl?: string;
  iconName?: string;
  name: string;
}) => {
  return (
    <Touchable
      style={{
        borderRadius: 15,
        padding: "10px 5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        flex: 1,
      }}
      _hover={{
        bg: colors.white,
      }}
    >
      <div
        style={{
          textAlign: "center",
          width: 25,
          marginRight: 5,
        }}
      >
        {iconName ? (
          <i className={iconName} />
        ) : (
          <img
            style={{
              width: "100%",
              objectFit: "contain",
              height: "auto",
              borderRadius: 20,
            }}
            src={imageUrl}
          />
        )}
      </div>

      <Text style={{ flex: 1 }}>{name}</Text>
    </Touchable>
  );
};

export const ContentModal = connectModal({
  name: "ContentModal",
})(_ContentModal);
